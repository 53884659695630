<template>
    <div class="autocomplete-dropdown">
        <input type="text" @keyup="enterMonitor" v-model="currentSearch" :placeholder="fieldInfo.emptyLabel" />
        <div class="dropdown-content" v-if="shouldShowOptions">
            <div
                class="dropdown-content__option"
                @mousedown="selectOption(option)"
                @keyup.enter="selectOption(option)"
                v-for="(option, index) in filteredOptions"
                :key="index"
                v-html="getOptionString(option.name)"
                tabindex="0"
            ></div>
        </div>
        <div
            v-if="currentSearch"
            class="clear-dropdown"
            @click="clearOption"
            tabindex="0"
            @keydown.enter="clearOption"
            ref="clearDropdown"
        >
            <Icon icon="close" class="clear-icon" />
        </div>
    </div>
</template>
<script>
import Icon from '@module/Icon';

export default {
    props: {
        fieldInfo: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        currentSearch: '',
        shouldShowOptions: false,
        selected: {},
    }),
    computed: {
        filteredOptions() {
            if (this.currentSearch.length < 2) return this.fieldInfo.options;
            const searchLower = this.currentSearch.toLowerCase();
            return this.fieldInfo.options.filter((option) => {
                const nameLower = option.name.toLowerCase();
                const locationStart = nameLower.indexOf('(');
                const nameString = locationStart !== -1 ? nameLower.substring(0, locationStart) : nameLower;

                return nameString.includes(searchLower) ? option : option.id === 'not_found';
            });
        },
    },
    watch: {
        currentSearch(newSearchString) {
            if (newSearchString.length > 2) {
                this.shouldShowOptions = true;
            } else {
                this.shouldShowOptions = false;
            }
        },
        selected(newSelectedObject) {
            if (newSelectedObject.id) {
                this.shouldShowOptions = false;
            }
        },
    },

    methods: {
        getOptionString(name) {
            if (!this.currentSearch) return name;

            const regEscape = (v) => v.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
            let reg = new RegExp(regEscape(this.currentSearch), 'ig');
            const locationStart = name.indexOf('(');
            const locationString = name.substring(locationStart);
            const nameMatchHighlightString = name
                .substring(0, locationStart)
                .replace(reg, (match) => `<span class="option--highlighted">${match}</span>`);
            return nameMatchHighlightString.concat(' ', locationString);
        },
        selectOption(option) {
            this.selected = option;
            this.exit();
        },
        exit() {
            if (!this.selected.id) {
                this.selected = {};
                this.currentSearch = '';
            } else {
                const tmpEl = document.createElement('div');
                tmpEl.innerHTML = this.selected.name;
                this.currentSearch = tmpEl.textContent || tmpEl.innerText;
            }
            this.$refs.clearDropdown && this.$refs.clearDropdown.focus();
            this.$emit('selected', this.selected);
        },
        // allows you to select the top option with "enter"
        enterMonitor: function (event) {
            if (event.key === 'Enter' && this.filteredOptions[0]) this.selectOption(this.filteredOptions[0]);
        },
        clearOption() {
            this.currentSearch = '';
            this.selected = {};
            this.$emit('selected', this.selected);
        },
    },
    mounted() {
        const selectedId = JSON.parse(localStorage.getItem('formData'))?.schooldistrictorganization;

        if (selectedId) {
            const selectedOption = this.filteredOptions.find((option) => option.id === selectedId) || {};
            this.selectOption(selectedOption);
        }

        if (this.fieldInfo.value) {
            const selectedOption = this.filteredOptions.find((option) => option.id === this.fieldInfo.value) || {};
            this.selectOption(selectedOption);
        }
    },
    components: {
        Icon,
    },
};
</script>
